import React, { Component } from "react"
import Layout from "../components/layout"
import { graphql } from "gatsby"
import { buildBlocks } from "../services/builder"

class Page extends Component {
  render() {
    this.props.pageContext.backgroundColor =
      this.props.data.wpBrandNews.PageBackground.pageBackgroundColour
    this.props.pageContext.date = this.props.data.wpBrandNews.date
    this.props.pageContext.excerpt = this.props.data.wpBrandNews.excerpt
    this.props.pageContext.featuredImage =
      this.props.data.wpBrandNews?.featuredImage?.node
    this.props.pageContext.title = this.props.data.wpBrandNews.title

    const blocks = buildBlocks(
      this.props.data.wpBrandNews.blocks,
      {},
      { ...this.props.pageContext, location: this.props.location },
      this.props.data.wpBrandNews.id
    )

    return (
      <Layout
        meta={this.props.data.wpBrandNews.Meta}
        path={this.props.pageContext.pagePath}
        cta={this.props.data.wpBrandNews.PageCTA}
        title={this.props.data.wpBrandNews.title}
      >
        {blocks}
      </Layout>
    )
  }
}

export const query = graphql`
  query ($id: String) {
    wpBrandNews(id: { eq: $id }) {
      id
      slug
      title
      date(formatString: "DD MMMM YYYY")
      excerpt
      featuredImage {
        node {
          altText
          publicUrl
          localFile {
            childImageSharp {
              gatsbyImageData(
                width: 1088
                aspectRatio: 1.67
                placeholder: BLURRED
                formats: [AUTO, WEBP, AVIF]
                transformOptions: { fit: COVER, cropFocus: CENTER }
              )
            }
          }
        }
      }
      Meta {
        fieldGroupName
        metaDescription
        metaTitle
        ogDescription
        ogTitle
        twitterCardType
        twitterDescription
        twitterTitle
        twitterImage {
          uri
        }
        ogImage {
          uri
        }
      }
      PageBackground {
        pageBackgroundColour
      }
      blocks {
        ...CoreBlock
        ...CoreFields
        innerBlocks {
          ...CoreBlock
          ...CoreFields
          innerBlocks {
            ...CoreBlock
            ...CoreFields
            innerBlocks {
              ...CoreBlock
              ...CoreFields
              innerBlocks {
                ...CoreBlock
                ...CoreFields
              }
            }
          }
        }
      }
    }
  }
`

export default Page
